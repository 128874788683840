import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'
import ColumnH1 from '../atoms/columnH1'
import Introduction004 from './Introduction004'
import Introduction005 from './Introduction005'
import Introduction006 from './Introduction006'
import Introduction007 from './Introduction007'
import Introduction008 from './Introduction008'
import Introduction009 from './Introduction009'
import Introduction010 from './Introduction010'
import Introduction011 from './Introduction011'
import Introduction012 from './Introduction012'

const Wrapper = styled.article`
  position: relative;
  margin-bottom: 30px;
  .p-txt-date {
    margin-top: -10px;
    color: #ababab;
  }
  section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 16px;
    color: #505050;
    line-height: 1.6em;
    h1 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #f5f5f5;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-bottom: 30px;
      margin-top: 10px;
      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #77d7c9;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }
    h2 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #ffffff;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-top: 10px;
      margin-bottom: 30px;
      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #d8d8d8;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }
    h3 {
      color: #414141;
      font-size: 16px;
      line-height: 1.4em;
      background: #ffffff;
      width: auto;
      padding: 10px 0 8px;
      margin-top: -10px;
      margin-bottom: 20px;
      border-top: solid 2px #e5e5e5;
      border-bottom: solid 2px #e5e5e5;
    }
    p {
      padding-bottom: 30px;
      line-height: 2;
      position: relative;
      span {
        background: linear-gradient(transparent 60%, #ff6 60%);
        font-weight: 600;
      }
      .p-list-link {
        position: absolute;
        top: 6px;
        right: 2px;
        &:before {
          display: block;
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          top: 7px;
          left: -14px;
          border-left: 8px solid #3ec7b3;
          border-top: 4px solid transparent;
          border-bottom: 4px solid transparent;
        }
      }
      &.txt-color-red {
        color: #ff6969;
      }
    }
    .link-div {
      width: 100%;
      display: flex;
      justify-content: left;
      padding-bottom: 30px;
      > div {
        width: 50px;
      }
      .link-p {
        width: calc(100% - 50px);
        .p-list-link {
          position: absolute;
          top: -3px;
          left: 30px;
          &:before {
            display: block;
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            top: 11px;
            left: -14px;
            border-left: 8px solid #3ec7b3;
            border-top: 4px solid transparent;
            border-bottom: 4px solid transparent;
          }
        }
      }
    }
    img {
      width: 100%;
      height: auto;
      padding-bottom: 30px;
    }
  }
  ${BreakPoints.large} {
  }
`
type Props = {
  /** 広告の番号 */
  no: number
}

/**
 * コラム記事詳細：記事本体
 * @constructor
 */
const ColumnDetailInner0049 = (props: Props): JSX.Element => (
  <Wrapper>
    <ColumnH1 label="欠勤とは？気になる欠勤控除の計算方法や残業代との関係は" />
    {/** TODO 川上申し送り事項：▼投稿日をここに表示 */}
    <p className="p-txt-date">2023.04.28</p>
    <section>
      {/** TODO 川上申し送り事項：▼記事投稿（？）で作成された内容がここに入る予定 */}
      <p>
        欠勤という言葉を耳にしたことがある経営者は多いと思いますが、実際にはどのような行為を指すのか、欠勤した場合の会社側の対応内容がどのようなものかについて、適切に理解できていますでしょうか。今回は、欠勤や欠勤控除の概要や計算方法、フレックスタイム制の場合や残業代との関係について、順を追って見ていきましょう。
      </p>
      <img src="/images/column/details/c0049.jpg" alt="キンクラコラム画像49" />

      <h1 id="toc1">欠勤とは</h1>
      <p>
        欠勤控除について述べる前に、まずはそもそも「欠勤」とは何かについておさらいをしておきましょう。
        <br />
        欠勤とは、労使間で交わされる労働契約書において
        <span>
          本来ならば仕事をしなければならないと定められている日に休むこと
        </span>
        をいいます。会社の指示により休む場合や有給休暇を取得する場合などとは異なり、労働提供すべき日に労働しなかったという扱いになることから、欠勤日には賃金が支払われることはありません。
      </p>

      <h1 id="toc2">欠勤控除とは</h1>
      <p>
        欠勤控除とは、
        <span>
          毎月社員に対して支払う賃金から、欠勤した日数もしくは時間分の賃金を控除すること
        </span>
        です。これは、「ノーワーク・ノーペイの原則」に沿って行っている措置です。労使間で労働契約を交わした場合、使用者は労働者に仕事を与え、労働者はその仕事を行うことで賃金を受け取るという契約が成立します。また、賃金については、労働をした後に受け取るものであることも法律で定められています。したがって、欠勤により働かなかった分については、使用者は賃金を支払う必要がないため、欠勤控除という概念が成り立っています。
      </p>

      <h1 id="toc3">欠勤控除の例外</h1>
      <p>
        欠勤控除の場合でも、賃金から差し引かないケースがあります。たとえば、月給制と同じく、
        <span>
          1ヶ月単位で賃金が固定されている「完全月給制」の場合、遅刻や早退、一日欠勤を行ったとしても欠勤控除が行われない
        </span>
        給与形態になります。
        <br />
        また、「日給制」や「時給制」の場合は、働いた日数分もしくは働いた時間分の賃金を受け取る給与形態になるため、欠勤控除という概念がそもそもありません。
        <br />
        一方、「日給月給制」や「月給制」、「年俸制」の場合には、遅刻や早退、一日欠勤をした場合に欠勤控除が差し引かれます。次の項目では、その欠勤控除の計算法について解説をしていきましょう。
      </p>

      {/** ここに9パターンの広告設定する */}
      {props.no === 0 && <Introduction004 />}
      {props.no === 1 && <Introduction005 />}
      {props.no === 2 && <Introduction006 />}
      {props.no === 3 && <Introduction007 />}
      {props.no === 4 && <Introduction008 />}
      {props.no === 5 && <Introduction009 />}
      {props.no === 6 && <Introduction010 />}
      {props.no === 7 && <Introduction011 />}
      {props.no === 8 && <Introduction012 />}

      <h1 id="toc4">欠勤控除の計算方法</h1>
      <p>欠勤控除の計算方法としては、主に次の2種類の計算方法が挙げられます。</p>
      <h2 id="toc4-1">
        ①<span>（基本給＋各種手当）÷1ヶ月の所定労働日数×欠勤日数</span>
      </h2>
      <p>
        欠勤日数を計算する際に用いる式になります。
        <br />
        なお、数式内の「所定労働日数」の算出方法については、法律による明確な規定はありません。欠勤が発生した月ごとに算出する方法を取った場合は計算式が煩雑になることから、「年間の労働日数÷12ヶ月」という式で算出する「1ヶ月平均の労働日数」を所定労働日数と扱う企業が多くみられます。
        <br />
        <br />
        たとえば、基本給+手当の給与額が20万円、所定労働日数が20日の社員が2日欠勤をした場合は、下記の金額が欠勤控除額となります。
        <br />
        20万円÷20日×2日＝2万円
      </p>
      <h2 id="toc4-2">
        ②<span>（基本給＋各種手当）÷1ヶ月の所定労働時間数×欠勤時間</span>
      </h2>
      <p>
        遅刻や早退などで、一日のうち数時間を欠勤した際に用いる式になります。
        <br />
        ①の数式で用いている所定労働日数を一日の労働時間数で除した「所定労働時間」を使って欠勤控除を算出します。
        <br />
        <br />
        たとえば、基本給+手当の給与額が20万円、1ヶ月の所定労働日数が20日、1日の労働時間数が8時間、3時間早く早退をした場合は、下記の金額が欠勤控除額となります。
        <br />
        なお、1ヶ月の所定労働時間数は、所定労働日数20日×1日の労働時間数8時間＝160時間になります。
        <br />
        20万円÷160時間☓3時間＝3,750円
      </p>
      <h1 id="toc6">フレックス勤務の場合の欠勤の取り扱い</h1>
      <p>
        フレックスタイムとは、前もって決定された総労働時間数の範囲で、始業時間・就業時間などを社員自身が決めることのできるシステムです。
        <br />
        「〇時に出社し、〇時には退社する」というルールがなく
        <span>
          、一定期間の中で働かなければならない労働時間数を満たしていれば良い
        </span>
        ことから、欠勤控除は行われません。あくまでも、あらかじめ決められた労働時間数をクリアしていれば問題ないとされるため、この時間帯は働かなければならないと言われている「コアタイム」内に遅刻・早退・欠勤をしたとしても労働時間数によっては欠勤控除が行われない点に注意しましょう。
        <br />
        なお、
        <span>
          実際の労働時間が総労働時間数に満たない場合は、他のケースと同様に欠勤控除が可能
        </span>
        になります。
      </p>

      <h1 id="toc7">欠勤があった月の残業代の取り扱い</h1>
      <p>
        前述の通り、社員の賃金は「ノーワーク・ノーペイの原則」に沿って支払わなければなりません。この考え方によれば、遅刻や早退、欠勤により欠勤控除が行われた場合でも、残業代に見合った金額で割増賃金を支払う必要がありますので、欠勤控除と残業代の相殺は認められません。
        <br />
        ただし、固定残業代を毎月支払っている会社の場合は注意が必要です。固定残業代は、欠勤控除の計算をする際に賃金に含めることは可能になりますが、実際に固定残業代に見合った時間の残業をしているにも関わらず欠勤控除を行うと、本来払うべき割増賃金が支払えていない状況に陥ってしまいます。
        <br />
        したがって、<span>割増賃金の未払いが発生しないよう留意</span>
        しながら対応する必要がありますので、注意しましょう。
      </p>

      <h1 id="toc7"> まとめ</h1>
      <p>
        欠勤の概要や欠勤控除の計算方法などについて一通り解説をしましたが、欠勤控除の計算方法にはっきりとした法律上の決まりがないことが現状です。したがって、法律の内容に抵触しない範囲内で、会社内でルールを定めていく必要があります。欠勤控除についての明確なルール付けがなされていない企業や、計算方法を認識していなかった企業は、一度社内で計算方法について検討をする必要があるでしょう。
      </p>
    </section>
  </Wrapper>
)

export default ColumnDetailInner0049
